/**
 * Global Reset of all HTML Elements
 *
 * Resetting all of our HTML Elements ensures a smoother
 * visual transition between browsers. If you don't believe me,
 * try temporarily commenting out this block of code, then go
 * and look at Mozilla versus Safari, both good browsers with
 * a good implementation of CSS. The thing is, all browser CSS
 * defaults are different and at the end of the day if visual
 * consistency is what we're shooting for, then we need to
 * make sure we're resetting all spacing elements.
 *
 */
html,
body {
  border: 0;
  font-family: "Helvetica-Neue", "Helvetica", Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
:focus {
  outline: 0;
}
@font-face {
  font-family: 'hb65';
  src: url('fonts/hb65/hb65-Regular.eot');
  src: url('fonts/hb65/hb65-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/hb65/hb65-Regular.woff') format('woff'), url('fonts/hb65/hb65-Regular.ttf') format('truetype'), url('fonts/hb65/hb65-Regular.svg#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubiklight.eot');
  src: url('fonts/rubik/Rubiklight.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubiklight.woff') format('woff'), url('fonts/rubik/Rubiklight.ttf') format('truetype'), url('fonts/rubik/Rubiklight.svg#icon') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubiklightitalic.eot');
  src: url('fonts/rubik/Rubiklightitalic.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubiklightitalic.woff') format('woff'), url('fonts/rubik/Rubiklightitalic.ttf') format('truetype'), url('fonts/rubik/Rubiklightitalic.svg#icon') format('svg');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubik.eot');
  src: url('fonts/rubik/Rubik.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubik.woff') format('woff'), url('fonts/rubik/Rubik.ttf') format('truetype'), url('fonts/rubik/Rubik.svg#icon') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikitalic.eot');
  src: url('fonts/rubik/Rubikitalic.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikitalic.woff') format('woff'), url('fonts/rubik/Rubikitalic.ttf') format('truetype'), url('fonts/rubik/Rubikitalic.svg#icon') format('svg');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikmedium.eot');
  src: url('fonts/rubik/Rubikmedium.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikmedium.woff') format('woff'), url('fonts/rubik/Rubikmedium.ttf') format('truetype'), url('fonts/rubik/Rubikmedium.svg#icon') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikmediumitalic.eot');
  src: url('fonts/rubik/Rubikmediumitalic.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikmediumitalic.woff') format('woff'), url('fonts/rubik/Rubikmediumitalic.ttf') format('truetype'), url('fonts/rubik/Rubikmediumitalic.svg#icon') format('svg');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikbold.eot');
  src: url('fonts/rubik/Rubikbold.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikbold.woff') format('woff'), url('fonts/rubik/Rubikbold.ttf') format('truetype'), url('fonts/rubik/Rubikbold.svg#icon') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikbolditalic.eot');
  src: url('fonts/rubik/Rubikbolditalic.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikbolditalic.woff') format('woff'), url('fonts/rubik/Rubikbolditalic.ttf') format('truetype'), url('fonts/rubik/Rubikbolditalic.svg#icon') format('svg');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikblack.eot');
  src: url('fonts/rubik/Rubikblack.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikblack.woff') format('woff'), url('fonts/rubik/Rubikblack.ttf') format('truetype'), url('fonts/rubik/Rubikblack.svg#icon') format('svg');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('fonts/rubik/Rubikblackitalic.eot');
  src: url('fonts/rubik/Rubikblackitalic.eot?#iefix') format('embedded-opentype'), url('fonts/rubik/Rubikblackitalic.woff') format('woff'), url('fonts/rubik/Rubikblackitalic.ttf') format('truetype'), url('fonts/rubik/Rubikblackitalic.svg#icon') format('svg');
  font-weight: 900;
  font-style: italic;
}
.grid-row {
  margin-left: -20px;
  margin-right: -20px;
}
.ib-box {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
}
.ib-col {
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
  word-spacing: normal;
  width: 100%;
}
.fl-col {
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  min-height: 1px;
  width: 100%;
}
.ib-col[class*="push"],
.fl-col[class*="push"],
.ib-col[class*="pull"],
.fl-col[class*="pull"],
.ib-col[class*="offset"],
.fl-col[class*="offset"] {
  position: relative;
}
.xs-24 {
  width: 100%;
}
.xs-23 {
  width: 95.83333333%;
}
.xs-22 {
  width: 91.66666667%;
}
.xs-21 {
  width: 87.5%;
}
.xs-20 {
  width: 83.33333333%;
}
.xs-19 {
  width: 79.16666667%;
}
.xs-18 {
  width: 75%;
}
.xs-17 {
  width: 70.83333333%;
}
.xs-16 {
  width: 66.66666667%;
}
.xs-15 {
  width: 62.5%;
}
.xs-14 {
  width: 58.33333333%;
}
.xs-13 {
  width: 54.16666667%;
}
.xs-12 {
  width: 50%;
}
.xs-11 {
  width: 45.83333333%;
}
.xs-10 {
  width: 41.66666667%;
}
.xs-9 {
  width: 37.5%;
}
.xs-8 {
  width: 33.33333333%;
}
.xs-7 {
  width: 29.16666667%;
}
.xs-6 {
  width: 25%;
}
.xs-5 {
  width: 20.83333333%;
}
.xs-4 {
  width: 16.66666667%;
}
.xs-3 {
  width: 12.5%;
}
.xs-2 {
  width: 8.33333333%;
}
.xs-1 {
  width: 4.16666667%;
}
.xs-push-24 {
  left: 100%;
}
.xs-push-23 {
  left: 95.83333333%;
}
.xs-push-22 {
  left: 91.66666667%;
}
.xs-push-21 {
  left: 87.5%;
}
.xs-push-20 {
  left: 83.33333333%;
}
.xs-push-19 {
  left: 79.16666667%;
}
.xs-push-18 {
  left: 75%;
}
.xs-push-17 {
  left: 70.83333333%;
}
.xs-push-16 {
  left: 66.66666667%;
}
.xs-push-15 {
  left: 62.5%;
}
.xs-push-14 {
  left: 58.33333333%;
}
.xs-push-13 {
  left: 54.16666667%;
}
.xs-push-12 {
  left: 50%;
}
.xs-push-11 {
  left: 45.83333333%;
}
.xs-push-10 {
  left: 41.66666667%;
}
.xs-push-9 {
  left: 37.5%;
}
.xs-push-8 {
  left: 33.33333333%;
}
.xs-push-7 {
  left: 29.16666667%;
}
.xs-push-6 {
  left: 25%;
}
.xs-push-5 {
  left: 20.83333333%;
}
.xs-push-4 {
  left: 16.66666667%;
}
.xs-push-3 {
  left: 12.5%;
}
.xs-push-2 {
  left: 8.33333333%;
}
.xs-push-1 {
  left: 4.16666667%;
}
.xs-pull-24 {
  right: 100%;
}
.xs-pull-23 {
  right: 95.83333333%;
}
.xs-pull-22 {
  right: 91.66666667%;
}
.xs-pull-21 {
  right: 87.5%;
}
.xs-pull-20 {
  right: 83.33333333%;
}
.xs-pull-19 {
  right: 79.16666667%;
}
.xs-pull-18 {
  right: 75%;
}
.xs-pull-17 {
  right: 70.83333333%;
}
.xs-pull-16 {
  right: 66.66666667%;
}
.xs-pull-15 {
  right: 62.5%;
}
.xs-pull-14 {
  right: 58.33333333%;
}
.xs-pull-13 {
  right: 54.16666667%;
}
.xs-pull-12 {
  right: 50%;
}
.xs-pull-11 {
  right: 45.83333333%;
}
.xs-pull-10 {
  right: 41.66666667%;
}
.xs-pull-9 {
  right: 37.5%;
}
.xs-pull-8 {
  right: 33.33333333%;
}
.xs-pull-7 {
  right: 29.16666667%;
}
.xs-pull-6 {
  right: 25%;
}
.xs-pull-5 {
  right: 20.83333333%;
}
.xs-pull-4 {
  right: 16.66666667%;
}
.xs-pull-3 {
  right: 12.5%;
}
.xs-pull-2 {
  right: 8.33333333%;
}
.xs-pull-1 {
  right: 4.16666667%;
}
.xs-offset-24 {
  margin-left: 100%;
}
.xs-offset-23 {
  margin-left: 95.83333333%;
}
.xs-offset-22 {
  margin-left: 91.66666667%;
}
.xs-offset-21 {
  margin-left: 87.5%;
}
.xs-offset-20 {
  margin-left: 83.33333333%;
}
.xs-offset-19 {
  margin-left: 79.16666667%;
}
.xs-offset-18 {
  margin-left: 75%;
}
.xs-offset-17 {
  margin-left: 70.83333333%;
}
.xs-offset-16 {
  margin-left: 66.66666667%;
}
.xs-offset-15 {
  margin-left: 62.5%;
}
.xs-offset-14 {
  margin-left: 58.33333333%;
}
.xs-offset-13 {
  margin-left: 54.16666667%;
}
.xs-offset-12 {
  margin-left: 50%;
}
.xs-offset-11 {
  margin-left: 45.83333333%;
}
.xs-offset-10 {
  margin-left: 41.66666667%;
}
.xs-offset-9 {
  margin-left: 37.5%;
}
.xs-offset-8 {
  margin-left: 33.33333333%;
}
.xs-offset-7 {
  margin-left: 29.16666667%;
}
.xs-offset-6 {
  margin-left: 25%;
}
.xs-offset-5 {
  margin-left: 20.83333333%;
}
.xs-offset-4 {
  margin-left: 16.66666667%;
}
.xs-offset-3 {
  margin-left: 12.5%;
}
.xs-offset-2 {
  margin-left: 8.33333333%;
}
.xs-offset-1 {
  margin-left: 4.16666667%;
}
.xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .sm-24 {
    width: 100%;
  }
  .sm-23 {
    width: 95.83333333%;
  }
  .sm-22 {
    width: 91.66666667%;
  }
  .sm-21 {
    width: 87.5%;
  }
  .sm-20 {
    width: 83.33333333%;
  }
  .sm-19 {
    width: 79.16666667%;
  }
  .sm-18 {
    width: 75%;
  }
  .sm-17 {
    width: 70.83333333%;
  }
  .sm-16 {
    width: 66.66666667%;
  }
  .sm-15 {
    width: 62.5%;
  }
  .sm-14 {
    width: 58.33333333%;
  }
  .sm-13 {
    width: 54.16666667%;
  }
  .sm-12 {
    width: 50%;
  }
  .sm-11 {
    width: 45.83333333%;
  }
  .sm-10 {
    width: 41.66666667%;
  }
  .sm-9 {
    width: 37.5%;
  }
  .sm-8 {
    width: 33.33333333%;
  }
  .sm-7 {
    width: 29.16666667%;
  }
  .sm-6 {
    width: 25%;
  }
  .sm-5 {
    width: 20.83333333%;
  }
  .sm-4 {
    width: 16.66666667%;
  }
  .sm-3 {
    width: 12.5%;
  }
  .sm-2 {
    width: 8.33333333%;
  }
  .sm-1 {
    width: 4.16666667%;
  }
  .sm-push-24 {
    left: 100%;
  }
  .sm-push-23 {
    left: 95.83333333%;
  }
  .sm-push-22 {
    left: 91.66666667%;
  }
  .sm-push-21 {
    left: 87.5%;
  }
  .sm-push-20 {
    left: 83.33333333%;
  }
  .sm-push-19 {
    left: 79.16666667%;
  }
  .sm-push-18 {
    left: 75%;
  }
  .sm-push-17 {
    left: 70.83333333%;
  }
  .sm-push-16 {
    left: 66.66666667%;
  }
  .sm-push-15 {
    left: 62.5%;
  }
  .sm-push-14 {
    left: 58.33333333%;
  }
  .sm-push-13 {
    left: 54.16666667%;
  }
  .sm-push-12 {
    left: 50%;
  }
  .sm-push-11 {
    left: 45.83333333%;
  }
  .sm-push-10 {
    left: 41.66666667%;
  }
  .sm-push-9 {
    left: 37.5%;
  }
  .sm-push-8 {
    left: 33.33333333%;
  }
  .sm-push-7 {
    left: 29.16666667%;
  }
  .sm-push-6 {
    left: 25%;
  }
  .sm-push-5 {
    left: 20.83333333%;
  }
  .sm-push-4 {
    left: 16.66666667%;
  }
  .sm-push-3 {
    left: 12.5%;
  }
  .sm-push-2 {
    left: 8.33333333%;
  }
  .sm-push-1 {
    left: 4.16666667%;
  }
  .sm-pull-24 {
    right: 100%;
  }
  .sm-pull-23 {
    right: 95.83333333%;
  }
  .sm-pull-22 {
    right: 91.66666667%;
  }
  .sm-pull-21 {
    right: 87.5%;
  }
  .sm-pull-20 {
    right: 83.33333333%;
  }
  .sm-pull-19 {
    right: 79.16666667%;
  }
  .sm-pull-18 {
    right: 75%;
  }
  .sm-pull-17 {
    right: 70.83333333%;
  }
  .sm-pull-16 {
    right: 66.66666667%;
  }
  .sm-pull-15 {
    right: 62.5%;
  }
  .sm-pull-14 {
    right: 58.33333333%;
  }
  .sm-pull-13 {
    right: 54.16666667%;
  }
  .sm-pull-12 {
    right: 50%;
  }
  .sm-pull-11 {
    right: 45.83333333%;
  }
  .sm-pull-10 {
    right: 41.66666667%;
  }
  .sm-pull-9 {
    right: 37.5%;
  }
  .sm-pull-8 {
    right: 33.33333333%;
  }
  .sm-pull-7 {
    right: 29.16666667%;
  }
  .sm-pull-6 {
    right: 25%;
  }
  .sm-pull-5 {
    right: 20.83333333%;
  }
  .sm-pull-4 {
    right: 16.66666667%;
  }
  .sm-pull-3 {
    right: 12.5%;
  }
  .sm-pull-2 {
    right: 8.33333333%;
  }
  .sm-pull-1 {
    right: 4.16666667%;
  }
  .sm-offset-24 {
    margin-left: 100%;
  }
  .sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .sm-offset-21 {
    margin-left: 87.5%;
  }
  .sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .sm-offset-18 {
    margin-left: 75%;
  }
  .sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .sm-offset-15 {
    margin-left: 62.5%;
  }
  .sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .sm-offset-12 {
    margin-left: 50%;
  }
  .sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .sm-offset-9 {
    margin-left: 37.5%;
  }
  .sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .sm-offset-6 {
    margin-left: 25%;
  }
  .sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .sm-offset-3 {
    margin-left: 12.5%;
  }
  .sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .md-24 {
    width: 100%;
  }
  .md-23 {
    width: 95.83333333%;
  }
  .md-22 {
    width: 91.66666667%;
  }
  .md-21 {
    width: 87.5%;
  }
  .md-20 {
    width: 83.33333333%;
  }
  .md-19 {
    width: 79.16666667%;
  }
  .md-18 {
    width: 75%;
  }
  .md-17 {
    width: 70.83333333%;
  }
  .md-16 {
    width: 66.66666667%;
  }
  .md-15 {
    width: 62.5%;
  }
  .md-14 {
    width: 58.33333333%;
  }
  .md-13 {
    width: 54.16666667%;
  }
  .md-12 {
    width: 50%;
  }
  .md-11 {
    width: 45.83333333%;
  }
  .md-10 {
    width: 41.66666667%;
  }
  .md-9 {
    width: 37.5%;
  }
  .md-8 {
    width: 33.33333333%;
  }
  .md-7 {
    width: 29.16666667%;
  }
  .md-6 {
    width: 25%;
  }
  .md-5 {
    width: 20.83333333%;
  }
  .md-4 {
    width: 16.66666667%;
  }
  .md-3 {
    width: 12.5%;
  }
  .md-2 {
    width: 8.33333333%;
  }
  .md-1 {
    width: 4.16666667%;
  }
  .md-push-24 {
    left: 100%;
  }
  .md-push-23 {
    left: 95.83333333%;
  }
  .md-push-22 {
    left: 91.66666667%;
  }
  .md-push-21 {
    left: 87.5%;
  }
  .md-push-20 {
    left: 83.33333333%;
  }
  .md-push-19 {
    left: 79.16666667%;
  }
  .md-push-18 {
    left: 75%;
  }
  .md-push-17 {
    left: 70.83333333%;
  }
  .md-push-16 {
    left: 66.66666667%;
  }
  .md-push-15 {
    left: 62.5%;
  }
  .md-push-14 {
    left: 58.33333333%;
  }
  .md-push-13 {
    left: 54.16666667%;
  }
  .md-push-12 {
    left: 50%;
  }
  .md-push-11 {
    left: 45.83333333%;
  }
  .md-push-10 {
    left: 41.66666667%;
  }
  .md-push-9 {
    left: 37.5%;
  }
  .md-push-8 {
    left: 33.33333333%;
  }
  .md-push-7 {
    left: 29.16666667%;
  }
  .md-push-6 {
    left: 25%;
  }
  .md-push-5 {
    left: 20.83333333%;
  }
  .md-push-4 {
    left: 16.66666667%;
  }
  .md-push-3 {
    left: 12.5%;
  }
  .md-push-2 {
    left: 8.33333333%;
  }
  .md-push-1 {
    left: 4.16666667%;
  }
  .md-pull-24 {
    right: 100%;
  }
  .md-pull-23 {
    right: 95.83333333%;
  }
  .md-pull-22 {
    right: 91.66666667%;
  }
  .md-pull-21 {
    right: 87.5%;
  }
  .md-pull-20 {
    right: 83.33333333%;
  }
  .md-pull-19 {
    right: 79.16666667%;
  }
  .md-pull-18 {
    right: 75%;
  }
  .md-pull-17 {
    right: 70.83333333%;
  }
  .md-pull-16 {
    right: 66.66666667%;
  }
  .md-pull-15 {
    right: 62.5%;
  }
  .md-pull-14 {
    right: 58.33333333%;
  }
  .md-pull-13 {
    right: 54.16666667%;
  }
  .md-pull-12 {
    right: 50%;
  }
  .md-pull-11 {
    right: 45.83333333%;
  }
  .md-pull-10 {
    right: 41.66666667%;
  }
  .md-pull-9 {
    right: 37.5%;
  }
  .md-pull-8 {
    right: 33.33333333%;
  }
  .md-pull-7 {
    right: 29.16666667%;
  }
  .md-pull-6 {
    right: 25%;
  }
  .md-pull-5 {
    right: 20.83333333%;
  }
  .md-pull-4 {
    right: 16.66666667%;
  }
  .md-pull-3 {
    right: 12.5%;
  }
  .md-pull-2 {
    right: 8.33333333%;
  }
  .md-pull-1 {
    right: 4.16666667%;
  }
  .md-offset-24 {
    margin-left: 100%;
  }
  .md-offset-23 {
    margin-left: 95.83333333%;
  }
  .md-offset-22 {
    margin-left: 91.66666667%;
  }
  .md-offset-21 {
    margin-left: 87.5%;
  }
  .md-offset-20 {
    margin-left: 83.33333333%;
  }
  .md-offset-19 {
    margin-left: 79.16666667%;
  }
  .md-offset-18 {
    margin-left: 75%;
  }
  .md-offset-17 {
    margin-left: 70.83333333%;
  }
  .md-offset-16 {
    margin-left: 66.66666667%;
  }
  .md-offset-15 {
    margin-left: 62.5%;
  }
  .md-offset-14 {
    margin-left: 58.33333333%;
  }
  .md-offset-13 {
    margin-left: 54.16666667%;
  }
  .md-offset-12 {
    margin-left: 50%;
  }
  .md-offset-11 {
    margin-left: 45.83333333%;
  }
  .md-offset-10 {
    margin-left: 41.66666667%;
  }
  .md-offset-9 {
    margin-left: 37.5%;
  }
  .md-offset-8 {
    margin-left: 33.33333333%;
  }
  .md-offset-7 {
    margin-left: 29.16666667%;
  }
  .md-offset-6 {
    margin-left: 25%;
  }
  .md-offset-5 {
    margin-left: 20.83333333%;
  }
  .md-offset-4 {
    margin-left: 16.66666667%;
  }
  .md-offset-3 {
    margin-left: 12.5%;
  }
  .md-offset-2 {
    margin-left: 8.33333333%;
  }
  .md-offset-1 {
    margin-left: 4.16666667%;
  }
  .md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .lg-24 {
    width: 100%;
  }
  .lg-23 {
    width: 95.83333333%;
  }
  .lg-22 {
    width: 91.66666667%;
  }
  .lg-21 {
    width: 87.5%;
  }
  .lg-20 {
    width: 83.33333333%;
  }
  .lg-19 {
    width: 79.16666667%;
  }
  .lg-18 {
    width: 75%;
  }
  .lg-17 {
    width: 70.83333333%;
  }
  .lg-16 {
    width: 66.66666667%;
  }
  .lg-15 {
    width: 62.5%;
  }
  .lg-14 {
    width: 58.33333333%;
  }
  .lg-13 {
    width: 54.16666667%;
  }
  .lg-12 {
    width: 50%;
  }
  .lg-11 {
    width: 45.83333333%;
  }
  .lg-10 {
    width: 41.66666667%;
  }
  .lg-9 {
    width: 37.5%;
  }
  .lg-8 {
    width: 33.33333333%;
  }
  .lg-7 {
    width: 29.16666667%;
  }
  .lg-6 {
    width: 25%;
  }
  .lg-5 {
    width: 20.83333333%;
  }
  .lg-4 {
    width: 16.66666667%;
  }
  .lg-3 {
    width: 12.5%;
  }
  .lg-2 {
    width: 8.33333333%;
  }
  .lg-1 {
    width: 4.16666667%;
  }
  .lg-push-24 {
    left: 100%;
  }
  .lg-push-23 {
    left: 95.83333333%;
  }
  .lg-push-22 {
    left: 91.66666667%;
  }
  .lg-push-21 {
    left: 87.5%;
  }
  .lg-push-20 {
    left: 83.33333333%;
  }
  .lg-push-19 {
    left: 79.16666667%;
  }
  .lg-push-18 {
    left: 75%;
  }
  .lg-push-17 {
    left: 70.83333333%;
  }
  .lg-push-16 {
    left: 66.66666667%;
  }
  .lg-push-15 {
    left: 62.5%;
  }
  .lg-push-14 {
    left: 58.33333333%;
  }
  .lg-push-13 {
    left: 54.16666667%;
  }
  .lg-push-12 {
    left: 50%;
  }
  .lg-push-11 {
    left: 45.83333333%;
  }
  .lg-push-10 {
    left: 41.66666667%;
  }
  .lg-push-9 {
    left: 37.5%;
  }
  .lg-push-8 {
    left: 33.33333333%;
  }
  .lg-push-7 {
    left: 29.16666667%;
  }
  .lg-push-6 {
    left: 25%;
  }
  .lg-push-5 {
    left: 20.83333333%;
  }
  .lg-push-4 {
    left: 16.66666667%;
  }
  .lg-push-3 {
    left: 12.5%;
  }
  .lg-push-2 {
    left: 8.33333333%;
  }
  .lg-push-1 {
    left: 4.16666667%;
  }
  .lg-pull-24 {
    right: 100%;
  }
  .lg-pull-23 {
    right: 95.83333333%;
  }
  .lg-pull-22 {
    right: 91.66666667%;
  }
  .lg-pull-21 {
    right: 87.5%;
  }
  .lg-pull-20 {
    right: 83.33333333%;
  }
  .lg-pull-19 {
    right: 79.16666667%;
  }
  .lg-pull-18 {
    right: 75%;
  }
  .lg-pull-17 {
    right: 70.83333333%;
  }
  .lg-pull-16 {
    right: 66.66666667%;
  }
  .lg-pull-15 {
    right: 62.5%;
  }
  .lg-pull-14 {
    right: 58.33333333%;
  }
  .lg-pull-13 {
    right: 54.16666667%;
  }
  .lg-pull-12 {
    right: 50%;
  }
  .lg-pull-11 {
    right: 45.83333333%;
  }
  .lg-pull-10 {
    right: 41.66666667%;
  }
  .lg-pull-9 {
    right: 37.5%;
  }
  .lg-pull-8 {
    right: 33.33333333%;
  }
  .lg-pull-7 {
    right: 29.16666667%;
  }
  .lg-pull-6 {
    right: 25%;
  }
  .lg-pull-5 {
    right: 20.83333333%;
  }
  .lg-pull-4 {
    right: 16.66666667%;
  }
  .lg-pull-3 {
    right: 12.5%;
  }
  .lg-pull-2 {
    right: 8.33333333%;
  }
  .lg-pull-1 {
    right: 4.16666667%;
  }
  .lg-offset-24 {
    margin-left: 100%;
  }
  .lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .lg-offset-21 {
    margin-left: 87.5%;
  }
  .lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .lg-offset-18 {
    margin-left: 75%;
  }
  .lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .lg-offset-15 {
    margin-left: 62.5%;
  }
  .lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .lg-offset-12 {
    margin-left: 50%;
  }
  .lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .lg-offset-9 {
    margin-left: 37.5%;
  }
  .lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .lg-offset-6 {
    margin-left: 25%;
  }
  .lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .lg-offset-3 {
    margin-left: 12.5%;
  }
  .lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .lg-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1400px) {
  .xl-24 {
    width: 100%;
  }
  .xl-23 {
    width: 95.83333333%;
  }
  .xl-22 {
    width: 91.66666667%;
  }
  .xl-21 {
    width: 87.5%;
  }
  .xl-20 {
    width: 83.33333333%;
  }
  .xl-19 {
    width: 79.16666667%;
  }
  .xl-18 {
    width: 75%;
  }
  .xl-17 {
    width: 70.83333333%;
  }
  .xl-16 {
    width: 66.66666667%;
  }
  .xl-15 {
    width: 62.5%;
  }
  .xl-14 {
    width: 58.33333333%;
  }
  .xl-13 {
    width: 54.16666667%;
  }
  .xl-12 {
    width: 50%;
  }
  .xl-11 {
    width: 45.83333333%;
  }
  .xl-10 {
    width: 41.66666667%;
  }
  .xl-9 {
    width: 37.5%;
  }
  .xl-8 {
    width: 33.33333333%;
  }
  .xl-7 {
    width: 29.16666667%;
  }
  .xl-6 {
    width: 25%;
  }
  .xl-5 {
    width: 20.83333333%;
  }
  .xl-4 {
    width: 16.66666667%;
  }
  .xl-3 {
    width: 12.5%;
  }
  .xl-2 {
    width: 8.33333333%;
  }
  .xl-1 {
    width: 4.16666667%;
  }
  .xl-push-24 {
    left: 100%;
  }
  .xl-push-23 {
    left: 95.83333333%;
  }
  .xl-push-22 {
    left: 91.66666667%;
  }
  .xl-push-21 {
    left: 87.5%;
  }
  .xl-push-20 {
    left: 83.33333333%;
  }
  .xl-push-19 {
    left: 79.16666667%;
  }
  .xl-push-18 {
    left: 75%;
  }
  .xl-push-17 {
    left: 70.83333333%;
  }
  .xl-push-16 {
    left: 66.66666667%;
  }
  .xl-push-15 {
    left: 62.5%;
  }
  .xl-push-14 {
    left: 58.33333333%;
  }
  .xl-push-13 {
    left: 54.16666667%;
  }
  .xl-push-12 {
    left: 50%;
  }
  .xl-push-11 {
    left: 45.83333333%;
  }
  .xl-push-10 {
    left: 41.66666667%;
  }
  .xl-push-9 {
    left: 37.5%;
  }
  .xl-push-8 {
    left: 33.33333333%;
  }
  .xl-push-7 {
    left: 29.16666667%;
  }
  .xl-push-6 {
    left: 25%;
  }
  .xl-push-5 {
    left: 20.83333333%;
  }
  .xl-push-4 {
    left: 16.66666667%;
  }
  .xl-push-3 {
    left: 12.5%;
  }
  .xl-push-2 {
    left: 8.33333333%;
  }
  .xl-push-1 {
    left: 4.16666667%;
  }
  .xl-pull-24 {
    right: 100%;
  }
  .xl-pull-23 {
    right: 95.83333333%;
  }
  .xl-pull-22 {
    right: 91.66666667%;
  }
  .xl-pull-21 {
    right: 87.5%;
  }
  .xl-pull-20 {
    right: 83.33333333%;
  }
  .xl-pull-19 {
    right: 79.16666667%;
  }
  .xl-pull-18 {
    right: 75%;
  }
  .xl-pull-17 {
    right: 70.83333333%;
  }
  .xl-pull-16 {
    right: 66.66666667%;
  }
  .xl-pull-15 {
    right: 62.5%;
  }
  .xl-pull-14 {
    right: 58.33333333%;
  }
  .xl-pull-13 {
    right: 54.16666667%;
  }
  .xl-pull-12 {
    right: 50%;
  }
  .xl-pull-11 {
    right: 45.83333333%;
  }
  .xl-pull-10 {
    right: 41.66666667%;
  }
  .xl-pull-9 {
    right: 37.5%;
  }
  .xl-pull-8 {
    right: 33.33333333%;
  }
  .xl-pull-7 {
    right: 29.16666667%;
  }
  .xl-pull-6 {
    right: 25%;
  }
  .xl-pull-5 {
    right: 20.83333333%;
  }
  .xl-pull-4 {
    right: 16.66666667%;
  }
  .xl-pull-3 {
    right: 12.5%;
  }
  .xl-pull-2 {
    right: 8.33333333%;
  }
  .xl-pull-1 {
    right: 4.16666667%;
  }
  .xl-offset-24 {
    margin-left: 100%;
  }
  .xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .xl-offset-21 {
    margin-left: 87.5%;
  }
  .xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .xl-offset-18 {
    margin-left: 75%;
  }
  .xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .xl-offset-15 {
    margin-left: 62.5%;
  }
  .xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .xl-offset-12 {
    margin-left: 50%;
  }
  .xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .xl-offset-9 {
    margin-left: 37.5%;
  }
  .xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .xl-offset-6 {
    margin-left: 25%;
  }
  .xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .xl-offset-3 {
    margin-left: 12.5%;
  }
  .xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .xl-offset-0 {
    margin-left: 0%;
  }
}
.clearfix-after:after,
.grid-row:after {
  content: "";
  display: block;
  clear: both;
}
.clearfix-both:before,
.clearfix-both:after {
  content: "";
  display: block;
  clear: both;
}
.fix-inline {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
}
.f-mdi {
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
}
.f-fa {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
}
.centered {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.centered-transform {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.centered-flex {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  outline: none !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
body {
  position: relative;
  font-family: 'Rubik';
  font-size: 15px;
  line-height: 1.3;
  font-weight: normal;
  min-width: 320px;
  color: #262829;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  body {
    font-size: 13px;
  }
}
.wrapper {
  height: auto !important;
  height: 100%;
  min-height: 100%;
  position: relative;
  display: block;
  padding-top: 72px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 44px;
  }
}
.container {
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
}
.container_nopad {
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 767px) {
  .container {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.content-narrow {
  position: relative;
  z-index: 1;
  max-width: 715px;
  margin-left: auto;
  margin-right: auto;
}
.content-narrow_min {
  max-width: 610px;
}
::selection {
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  text-shadow: none;
  background: slategray;
  background: rgba(112, 128, 144, 0.99);
}
::-moz-selection {
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  text-shadow: none;
  background: slategray;
  background: rgba(112, 128, 144, 0.99);
}
body {
  scrollbar-base-color: #BDC3C7;
  scrollbar-3dlight-color: transparent;
  scrollbar-highlight-color: transparent;
  scrollbar-track-color: #ECF0F1;
  scrollbar-arrow-color: #BDC3C7;
  scrollbar-shadow-color: transparent;
  scrollbar-dark-shadow-color: transparent;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #BDC3C7;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background-color: #ECF0F1;
}
.grid-row_nopad {
  margin-right: 0;
  margin-left: 0;
}
.grid-row_nopad > .fl-col,
.grid-row_nopad > .ib-box > .ib-col {
  padding-right: 0;
  padding-left: 0;
}
.grid-row_form {
  margin-right: -10px;
  margin-left: -10px;
}
.grid-row_form .fl-col,
.grid-row_form .ib-col {
  padding-right: 10px;
  padding-left: 10px;
}
.grid-row_vam .ib-col {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .grid-row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .grid-row .ib-col,
  .grid-row .fl-col {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.tb {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.tb__r {
  display: table-row;
}
.tb__c {
  display: table-cell;
  vertical-align: middle;
}
.tb_vab .tb__c {
  vertical-align: bottom;
  height: 100%;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.hidden {
  display: none !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear 0s;
  -moz-transition: opacity 0.15s linear 0s;
  -o-transition: opacity 0.15s linear 0s;
  transition: opacity 0.15s linear 0s;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
.collapse__wrap {
  padding: 20px 0 0;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  -moz-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  -o-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.warning-color {
  color: #FEE82C !important;
}
.important-color {
  color: #C73029 !important;
}
.success-color {
  color: #3FA747 !important;
}
.info-color {
  color: #1268AD !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 16px;
  font-weight: 600;
  line-height: 1.16;
  font-family: 'hb65';
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
@media (max-width: 767px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-size: 0.4px;
  }
}
h1,
.h1 {
  font-size: 54px;
  margin-bottom: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 48px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
h2,
.h2 {
  font-size: 42px;
}
@media (min-width: 768px) and (max-width: 991px) {
  h2,
  .h2 {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 22px;
  }
}
h3,
.h3 {
  font-size: 32px;
}
@media (min-width: 768px) and (max-width: 991px) {
  h3,
  .h3 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 18px;
  }
}
h4,
.h4 {
  font-size: 24px;
}
@media (max-width: 767px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}
h5,
.h5,
h6,
.h6 {
  font-size: 20px;
}
@media (max-width: 767px) {
  h5,
  .h5,
  h6,
  .h6 {
    font-size: 15px;
  }
}
.pretitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 12px;
}
a,
.link {
  position: relative;
  display: inline-block;
}
a,
.link,
a:after,
.link:after,
a:before,
.link:before {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
a,
.link,
a:focus,
.link:focus {
  text-decoration: none;
  color: #1268AD;
}
a span,
.link span,
a:focus span,
.link:focus span {
  border-bottom: 1px solid rgba(18, 104, 173, 0.2);
}
a span,
.link span {
  -webkit-transition: border-bottom 0.2s ease-in-out 0s;
  -moz-transition: border-bottom 0.2s ease-in-out 0s;
  -o-transition: border-bottom 0.2s ease-in-out 0s;
  transition: border-bottom 0.2s ease-in-out 0s;
}
a:hover,
.link:hover {
  color: #C73029;
  text-decoration: none;
}
a:hover span,
.link:hover span {
  border-bottom-color: rgba(199, 48, 41, 0.2);
}
a.disabled,
.link.disabled,
a.disabled:focus,
.link.disabled:focus,
a.disabled:hover,
.link.disabled:hover {
  cursor: default;
  color: rgba(38, 40, 41, 0.4);
}
a .link-icon,
.link .link-icon {
  vertical-align: baseline;
  font-size: 110%;
}
.link_dashed span,
.link_dashed:focus span {
  border-bottom-style: dashed;
}
.link_dotted span,
.link_dotted:focus span {
  border-bottom-style: dotted;
}
.link_bold {
  font-weight: bold;
}
.link_baseline {
  vertical-align: baseline;
}
.link_collapse:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  position: relative;
  width: 16px;
  height: 16px;
}
.link_collapse .link__text {
  margin-right: 8px;
}
.link_collapse.collapsed:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.bg-img {
  background: center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.bg-img_contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.bg-fade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(38, 40, 41, 0.6);
}
.menu-open {
  overflow: hidden;
}
.mmenu {
  list-style-type: none;
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  text-align: center;
  padding-top: 20px;
  margin-bottom: -10px;
  white-space: nowrap;
}
.mmenu__link {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'hb65';
}
.mmenu__link_dd {
  position: relative;
  padding-right: 20px;
}
.mmenu__link_dd:after {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  content: "\F1D9";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  opacity: .4;
  font-size: 20px;
  line-height: 16px;
}
.mmenu__item {
  position: relative;
  white-space: normal;
  word-spacing: normal;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 10px;
}
.mmenu__item:last-child {
  margin-right: 0;
}
.msubmenu {
  list-style-type: none;
  display: block;
  position: absolute;
  top: 300%;
  left: 0;
  padding: 30px;
  border-radius: 18px;
  width: 280px;
  background-color: #262829;
  text-align: left;
  -webkit-box-shadow: 0 20px 40px -20px rgba(38, 40, 41, 0.6);
  -moz-box-shadow: 0 20px 40px -20px rgba(38, 40, 41, 0.6);
  box-shadow: 0 20px 40px -20px rgba(38, 40, 41, 0.6);
  -webkit-transition: all 0.15s ease-in-out 0s;
  -moz-transition: all 0.15s ease-in-out 0s;
  -o-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  visibility: hidden;
  opacity: 0;
}
.msubmenu:before,
.msubmenu:after {
  content: "";
  display: block;
  position: absolute;
  top: -19px;
}
.msubmenu:after {
  left: 44px;
  width: 52px;
  height: 19px;
  background-color: #262829;
}
.msubmenu:before {
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 0;
}
.msubmenu__item {
  margin-bottom: 7px;
}
.msubmenu__item:last-child {
  margin-bottom: 0;
}
.msubmenu__link,
.msubmenu__link:focus {
  color: #ffffff;
}
.msubmenu__link span,
.msubmenu__link:focus span {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.msub-open .msubmenu {
  top: 200%;
  visibility: visible;
  opacity: 1;
}
p,
div {
  margin: 0;
}
hr {
  border-color: #F1F4F4;
  margin: 0;
}
img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
}
.text {
  position: relative;
  z-index: 1;
  color: #262829;
  margin-bottom: 20px;
  text-align: justify;
}
.text:last-child {
  margin-bottom: 0;
}
.text iframe {
  width: 100%;
  height: 400px;
  margin: 20px auto;
}
.text p,
.text ul,
.text ol {
  margin-bottom: 16px;
}
.text p:last-child,
.text ul:last-child,
.text ol:last-child {
  margin-bottom: 0;
}
.text img {
  margin: 30px auto;
}
.text a {
  vertical-align: baseline;
}
.text ul,
.text ol {
  margin-left: 20px;
}
.text ul li,
.text ol li {
  margin-bottom: 8px;
}
.text ul li:last-child,
.text ol li:last-child {
  margin-bottom: 0;
}
.text blockquote {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #ff7863;
}
.text blockquote p {
  margin-bottom: 10px;
}
.text blockquote p:last-child {
  margin-bottom: 0;
}
.text table {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 13px;
  color: #262829;
}
.text table td {
  padding: 5px;
  border: 1px solid #F1F4F4;
}
.text table tr:nth-child(odd) {
  background-color: #d4d9de;
}
.text table tr:hover {
  background-color: #ccd2d7;
}
.text_l {
  font-size: 18px;
}
@media (max-width: 767px) {
  .text_l {
    font-size: 15px;
  }
}
.text_s {
  font-size: 13px;
}
.text_s p,
.text_s ul,
.text_s ol {
  margin-bottom: 8px;
}
.text_s img {
  margin: 12px auto;
}
.text_italic {
  font-style: italic;
}
.text_light-fade {
  color: rgba(255, 255, 255, 0.8);
}
.text_ul-mod ul {
  margin-left: 0;
  list-style-type: none;
}
.text_ul-mod ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
}
.text_ul-mod ul li:last-child {
  margin-bottom: 0;
}
.text_ul-mod ul li:after {
  display: block;
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
}
@media (max-width: 767px) {
  .text img {
    margin: 16px auto;
  }
}
.text-ellipsis {
  display: block;
  max-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.note {
  font-size: 13px;
  color: #262829;
}
.note_btn {
  display: inline-block;
  vertical-align: middle;
  max-width: 230px;
  font-size: 15px;
}
.styled-list {
  list-style-type: none;
}
.styled-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
}
.styled-list li:last-child {
  margin-bottom: 0;
}
.styled-list li:after {
  display: block;
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
}
.table-wrap {
  overflow-y: auto;
  margin: 16px 0;
}
.yt-video {
  display: block;
  width: 100%;
  height: 480px;
  max-width: 910px;
  margin: 0 auto 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .yt-video {
    margin-bottom: 30px;
    height: 370px;
  }
}
@media (max-width: 767px) {
  .yt-video {
    margin-bottom: 20px;
    height: 150px;
  }
}
.header {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 72px;
  background-color: #ffffff;
  z-index: 500;
  max-width: 100%;
  padding: 14px 0;
}
.header__wrap {
  display: block;
  border-radius: 18px;
  width: 100%;
  text-align: center;
  background: #F1F4F4 url('/assets/images/leafs.png') 0 0 no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media (max-width: 767px) {
  .header {
    height: 44px;
    padding: 0;
  }
  .header__wrap {
    border-radius: 0;
  }
}
.logo {
  vertical-align: top;
}
.logo__img {
  max-height: 44px;
}
.logo:hover {
  opacity: .8;
}
@media (max-width: 767px) {
  .logo__img {
    max-height: 34px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.empty-footer {
  height: 185px;
}
.footer {
  position: relative;
  margin-top: -185px;
  height: 185px;
  min-width: 320px;
  z-index: 0;
  background-color: #ffffff;
}
.footer__cont {
  padding-top: 50px;
  padding-bottom: 60px;
  border-top: 1px solid rgba(38, 40, 41, 0.1);
}
.footer__dnext {
  text-align: right;
}
.footer__phone {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.footer__phone a,
.footer__phone a:focus {
  color: #262829;
}
.footer__email {
  font-size: 18px;
  margin-bottom: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__cont {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer__dnext {
    text-align: center;
    margin-top: 16px;
  }
  .footer__cont {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.footer-wrap {
  position: relative;
}
.fmenu-title {
  margin-bottom: 16px;
  vertical-align: top;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.4px;
  font-family: 'hb65';
  text-transform: uppercase;
}
.fmenu-title:last-child {
  margin-bottom: 0;
}
.social {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  font-size: 24px;
  margin-bottom: -10px;
}
.social__el {
  white-space: normal;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 10px;
}
.social__el:last-child {
  margin-right: 0;
}
.social__inst,
.social__inst:focus {
  color: #C53C75;
}
.social__vk,
.social__vk:focus {
  color: #537497;
}
.social__fb,
.social__fb:focus {
  color: #456BAE;
}
.fmenu {
  list-style-type: none;
  margin-bottom: 18px;
}
.fmenu__el {
  margin-bottom: 8px;
}
.fmenu:last-child,
.fmenu__el:last-child {
  margin-bottom: 0;
}
.sbox {
  position: relative;
  margin-top: 30px;
  margin-bottom: 80px;
}
.sbox__title {
  margin-bottom: 40px;
}
.sbox_bgi {
  background: #262829 right center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 60px;
}
.sbox_last-nomb {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sbox {
    margin-bottom: 50px;
  }
}
